import React from 'react'
import styles from './Serve.module.scss';

function Serve() {
    return (
        <div className={styles.container} >
            <div className={styles.top}>
                <p className={styles.head}>Whom We Serve</p>
                <p className={styles.content}>Tailored Solutions for Every Need</p>
            </div>
            <div className={styles.contact}>
                <div className={styles.left}>
                    <p className={styles.details}>Our car wash services are designed to meet the unique requirements of diverse industries and sectors.</p>
                    <a href='#footer'><button>Contact Us</button></a>
                </div>
                <div className={styles.right}>
                    <p>Airpots</p>
                    <p>Residential Societies</p>
                    <p>Dealerships</p>
                    <p>Ports</p>
                    <a href='#footer'><button>Contact Us</button></a>
                </div>
            </div>
        </div>
    )
}

export default Serve