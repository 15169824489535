// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Contact_container__NrI8Z {
  padding: 50px;
}
.Contact_container__NrI8Z .Contact_right__2f47H ul {
  display: flex;
  flex-direction: column;
  gap: 0.813rem;
}
.Contact_container__NrI8Z .Contact_right__2f47H ul .Contact_head__ovsz6 {
  color: black;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.Contact_container__NrI8Z .Contact_right__2f47H ul li {
  display: flex;
  align-items: start;
  gap: 11px;
  list-style: none;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Contact.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;AACJ;AACQ;EAQI,aAAA;EACA,sBAAA;EACA,aAAA;AANZ;AAHY;EACI,YAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AAKhB;AAAY;EACI,aAAA;EACA,kBAAA;EACA,SAAA;EACA,gBAAA;EACA,mBAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AAEhB","sourcesContent":[".container{\n    padding: 50px;\n    .right{\n        ul{\n            .head{\n                color: black;\n                font-size: 1rem;\n                font-style: normal;\n                font-weight: 500;\n                line-height: normal;\n            }\n            display: flex;\n            flex-direction: column;\n            gap:0.813rem;\n            li{\n                display: flex;\n                align-items: start;\n                gap:11px;\n                list-style: none;\n                font-size: 0.875rem;\n                font-style: normal;\n                font-weight: 400;\n                line-height: normal;\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Contact_container__NrI8Z`,
	"right": `Contact_right__2f47H`,
	"head": `Contact_head__ovsz6`
};
export default ___CSS_LOADER_EXPORT___;
