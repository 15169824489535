import React from 'react'
import styles from './Footer.module.scss';
import { Link } from 'react-router-dom';
import eco from '../../assets/eco-removebg-preview.png'

export default function Footer() {
    return (
        <footer id='footer' className={styles.footer}>
            <div className={styles.container}>
                <div className={styles.left}>
                    <ul>
                        <Link style={{color:"black"}} to={'/contact'}><li>Contact Us</li></Link>
                        <Link style={{color:"black"}} to={'/terms'}><li>Terms & Condition</li></Link>
                        <Link style={{color:"black"}} to={'/refund&cancellationpolicy'}><li>Refund & Cancellation Policy</li></Link>
                    </ul>
                </div>
                <div className={styles.right}>
                <ul>
  <li className={styles.head}>EAGRU SERVICES PRIVATE LIMITED</li>

  {/* Address - Opens Google Maps */}
  <li>
    <div>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M16.9497 6.05025C14.2161 3.31658 9.78392 3.31658 7.05025 6.05025C4.31658 8.78392 4.31658 13.2161 7.05025 15.9497L11.2937 20.1932C11.684 20.5835 12.3159 20.5836 12.7067 20.1928L16.9497 15.9497C19.6834 13.2161 19.6834 8.78392 16.9497 6.05025ZM5.63604 4.63604C9.15076 1.12132 14.8492 1.12132 18.364 4.63604C21.8787 8.15076 21.8787 13.8492 18.364 17.364L14.8617 20.8662C14.8303 20.8976 14.8754 20.8525 14.844 20.8839L14.1209 21.607C12.9496 22.7783 11.0514 22.7793 9.87952 21.6074L5.63604 17.364C2.12132 13.8492 2.12132 8.15076 5.63604 4.63604ZM12 9C10.8954 9 10 9.89543 10 11C10 12.1046 10.8954 13 12 13C13.1046 13 14 12.1046 14 11C14 9.89543 13.1046 9 12 9ZM8 11C8 8.79086 9.79086 7 12 7C14.2091 7 16 8.79086 16 11C16 13.2091 14.2091 15 12 15C9.79086 15 8 13.2091 8 11Z" fill="#111827"/>
      </svg>
    </div>
    <p>
      <a href="https://www.google.com/maps/search/?api=1&query=Prestige+Lakeridge,+Uttarahalli,+Bangalore+560004" target="_blank" rel="noopener noreferrer">
        4197, Prestige Lakeridge, Uttarahalli, BANGALORE,<br/>
        Karnataka<br/>
        560004
      </a>
    </p>
  </li>

  {/* Email - Opens the default mail client */}
  <li>
    <div>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M2.00011 7C2.00011 5.34315 3.34325 4 5.00011 4H19.0001C20.657 4 22.0001 5.34315 22.0001 7V7.98448C22.0002 7.99422 22.0002 8.00395 22.0001 8.01368V17C22.0001 18.6569 20.657 20 19.0001 20H5.00011C3.34325 20 2.00011 18.6569 2.00011 17V8.01367C1.99996 8.00395 1.99996 7.99422 2.00011 7.98449V7ZM4.00011 9.86852V17C4.00011 17.5523 4.44782 18 5.00011 18H19.0001C19.5524 18 20.0001 17.5523 20.0001 17V9.86852L13.6642 14.0925C12.6565 14.7642 11.3437 14.7642 10.336 14.0925L4.00011 9.86852ZM20.0001 7.46482L12.5548 12.4283C12.2189 12.6523 11.7813 12.6523 11.4454 12.4283L4.00011 7.46482V7C4.00011 6.44772 4.44782 6 5.00011 6H19.0001C19.5524 6 20.0001 6.44772 20.0001 7V7.46482Z" fill="#111827"/>
      </svg>
    </div>
    <p>
      <a href="mailto:support@eagruservices.in">support@eagruservices.in</a>
    </p>
  </li>

  {/* Phone - Opens the dialer */}
  <li>
    <div>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M2 5C2 3.34315 3.34315 2 5 2H8.27924C9.1401 2 9.90438 2.55086 10.1766 3.36755L11.6744 7.86098C11.9892 8.80521 11.5617 9.83718 10.6715 10.2823L9.31494 10.9606C10.2016 12.5092 11.4908 13.7984 13.0394 14.6851L13.7177 13.3285C14.1628 12.4383 15.1948 12.0108 16.139 12.3256L20.6325 13.8234C21.4491 14.0956 22 14.8599 22 15.7208V19C22 20.6569 20.6569 22 19 22H18C9.16344 22 2 14.8366 2 6V5ZM5 4C4.44772 4 4 4.44772 4 5V6C4 13.732 10.268 20 18 20H19C19.5523 20 20 19.5523 20 19V15.7208L15.5066 14.2229L14.3779 16.4802C14.1379 16.9602 13.5618 17.1652 13.0725 16.9446C10.4054 15.7421 8.25786 13.5946 7.05538 10.9275C6.83479 10.4382 7.03975 9.86209 7.51979 9.62207L9.77705 8.49344L8.27924 4H5Z" fill="#111827"/>
      </svg>
    </div>
    <p>
      <a href="tel:+919148853907">+91 91488 53907</a>
    </p>
  </li>
</ul>

                </div>
            </div>
            <div className={styles.bottom}>
                <img src={eco} />
                <p>Committed to Sustainability: Using 100% Eco-Friendly Products and Water-Saving Technologies.</p>
            </div>
        </footer>
    )
}
