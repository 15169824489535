import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Terms from './Pages/Terms';
import Refund from './Pages/Refund';
import Contact from './Pages/Contact';
import ScrollToTop from './scroll';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
    <ScrollToTop />
    <Routes>
        <Route  path="/" element={ <App />} />
        <Route  path="/terms" element={ <Terms />} />
        <Route  path="/refund&cancellationpolicy" element={ <Refund />} />
        <Route  path="/contact" element={ <Contact />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
