import React from 'react'
import terms from './Terms.json'
import styles from './Terms.module.scss'

function Terms() {
    return (
        <div className={styles.container}>
            <div>
                <p style={{fontSize:"2rem", fontWeight:"500", marginBottom:"5rem"}}>Terms & Conditions</p>
                <p>Last updated on 08-09-2024 15:50:10</p>
                <p>These Terms and Conditions, along with privacy policy or other terms (“Terms”) constitute a binding agreement by and between EAGRU SERVICES PRIVATE LIMITED, ( “Website Owner” or “we” or “us” or “our”) and you (“you” or “your”) and relate to your use of our website, goods (as applicable) or services (as applicable) (collectively, “Services”).</p>
            </div>
            <div>
                <p>By using our website and availing the Services, you agree that you have read and accepted these Terms (including the Privacy Policy). We reserve the right to modify these Terms at any time and without assigning any reason. It is your responsibility to periodically review these Terms to stay informed of updates.</p>
            </div>
            <div>
                <p>The use of this website or availing of our Services is subject to the following terms of use:</p>
            </div>
            <div>
                <ul>
                    {
                        terms.data.map(item=>(
                            <li>{item}</li>
                        ))
                    }
                </ul>
            </div>
        </div>

    )
}

export default Terms