import styles from './NavBar.module.scss'

function NavBar() {
    return (
    <div className={styles.navbar}>
        <p className={styles.title}>Eagru</p>
        <div className={styles.navRight}>
            <p>Home</p>
            <a href='#footer'><p>Contact Us</p></a>
        </div>
    </div>
    )
}

export default NavBar