// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Terms_container__rjjen {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  gap: 30px;
  padding: 50px;
}
.Terms_container__rjjen p {
  font-size: 1.5rem;
}
@media (max-width: 1100px) {
  .Terms_container__rjjen p {
    font-size: 1rem;
  }
}
.Terms_container__rjjen ul {
  font-size: 1.5rem;
}
@media (max-width: 1100px) {
  .Terms_container__rjjen ul {
    font-size: 1rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/Pages/Terms.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,eAAA;EACA,SAAA;EACA,aAAA;AACJ;AAAI;EACI,iBAAA;AAER;AADQ;EAFJ;IAGQ,eAAA;EAIV;AACF;AAFI;EACI,iBAAA;AAIR;AAHQ;EAFJ;IAGQ,eAAA;EAMV;AACF","sourcesContent":[".container{\n    display: flex;\n    flex-direction: column;\n    max-width: 100%;\n    gap:30px;\n    padding: 50px;\n    p{\n        font-size: 1.5rem;\n        @media (max-width: 1100px) {\n            font-size: 1rem;\n        }\n    }\n    ul{\n        font-size: 1.5rem;\n        @media (max-width: 1100px) {\n            font-size: 1rem;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Terms_container__rjjen`
};
export default ___CSS_LOADER_EXPORT___;
