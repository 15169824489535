import React, { useState } from 'react'
import styles from './Service.module.scss';
import CardData from './CardData.json';
import Cards from './Cards/Cards';

interface Input{
    head:string
    content:string
}

export default function Service() {
    const [Card_data, setCard_data]=useState<Input[]>(CardData)
    return (
        <div className={styles.service}>
            <div className={styles.container}>
                <p className={styles.head}>Our  Car Wash Services</p>
                <div className={styles.cards}>
                    {
                        Card_data.map((item:Input, index:number)=>(
                            <Cards key={index} head={item.head} content={item.content} />
                        ))
                    }
                </div>
            </div>
        </div>
    )
}
