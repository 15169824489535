import styles from './App.module.scss';
import Footer from './Components/Footer/Footer';
import Hero from './Components/Hero/Hero';
import NavBar from './Components/NavBar/NavBar';
import Pricing from './Components/Pricing/Pricing';
import Serve from './Components/Serve/Serve';
import Service from './Components/Services/Service';
import WhyusPage from './Components/WhyUsPage/WhyusPage';

function App() {
  return (
    <div className={styles.main}>
      <NavBar />
      <Hero />
      <Service />
      <WhyusPage />
      <Pricing />
      <Serve />
      <Footer />
    </div>
  );
}

export default App;