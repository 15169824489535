import React from 'react'
import styles from './Pricing.module.scss'
import data from './pricing.json'
import Cards from './Cards/Cards'

interface Input{
    head:string
    content:string
}

function Pricing() {
    return (
        <div className={styles.container}>
            <div className={styles.top}>
                <p className={styles.head}>Pricing & Packages</p>
                <p className={styles.content}>Flexible Plans for Every Business</p>
            </div>
            <div className={styles.pricingCard}>
                {
                    data.map((item, index)=>(
                        <Cards key={index} head={item.head} content={item.content} />
                    ))
                }
            </div>
        </div>
    )
}

export default Pricing