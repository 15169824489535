import React from 'react'
import terms from './Refund.json';
import styles from './Terms.module.scss'

export default function Refund() {
  return (
    <div className={styles.container}>
        <div>
            <p style={{fontSize:"2rem", fontWeight:"500", marginBottom:"5rem"}}>Refund & Cancellation Policy</p>
            <p>Last updated on 18-09-2024</p>
            <p>At EAGRU SERVICES PRIVATE LIMITED, we strive to ensure the highest level of satisfaction with our car wash services. However, if you encounter any issues, we have implemented the following refund and cancellation policy to assist our valued customers.</p>
        </div>
        <div>
            <h2>Cancellation Policy:</h2>
                <ul>
                    {
                        terms.cancel.map(item=>(
                            <li>{item}</li>
                        ))
                    }
                </ul>
                <h2>Refund Policy:</h2>
                <ul>
                    {
                        terms.refund.map(item=>(
                            <li>{item}</li>
                        ))
                    }
                </ul>
                <h2>Damages or Defects:</h2>
                <ul>
                    {
                        terms.damages.map(item=>(
                            <li>{item}</li>
                        ))
                    }
                </ul>
                <h2>Processing Refunds:</h2>
                <ul>
                    {
                        terms.processing.map(item=>(
                            <li>{item}</li>
                        ))
                    }
                </ul>
                <h2>Non-Refundable Services:</h2>
                <ul>
                    {
                        terms.nonRefundable.map(item=>(
                            <li>{item}</li>
                        ))
                    }
                </ul>
        </div>
        <div>
            <p>We value your trust in us, and our goal is to provide seamless, hassle-free services every time. Please feel free to reach out to our customer support team for any further assistance.</p>
        </div>
        <div>
            <h2 style={{margin:"0"}}>Contact Information:</h2>
            <p><a href="mailto:support@eagruservices.in">support@eagruservices.in</a></p>
            <p><a href="tel:+919148853907">+91 91488 53907</a></p>
        </div>
    </div>
  )
}
