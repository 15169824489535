import React from 'react'
import styles from './WhyUsPage.module.scss';
import wash from '../../assets/wash.png';
import data from './whyUs.json';

interface Input{
    head:string
    details:string
}

function WhyusPage() {
    return (
        <div className={styles.mainContainer}>
            <div className={styles.subContainer}>
                <p className={styles.head}>Why Choose Us</p>
                <div className={styles.container}>
                    <img src={wash} alt='carWash'/>
                    <div className={styles.whyUs}>
                        {
                            data.map((item:Input, index:number)=>(
                                <div key={index} className={styles.content}>
                                    <p className={styles.head}>{item.head}</p>
                                    <p className={styles.details}>{item.details}</p>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
            <div className={styles.mobileContainer}>
                <p className={styles.head}>Why Choose Us</p>
                <img src={wash} alt='carWash'/>
                <div className={styles.whyUs}>
                        {
                            data.map((item:Input, index:number)=>(
                                <div key={index} className={styles.content}>
                                    <p className={styles.head}>{item.head}</p>
                                    <p className={styles.details}>{item.details}</p>
                                </div>
                            ))
                        }
                    </div>
            </div>

        </div>
    )
}

export default WhyusPage