import React, { useEffect, useState } from 'react'
import styles from './Hero.module.scss';
import hero from '../../assets/hero.png';
import hero_mobile from '../../assets/hero_mobile.png';

export default function Hero() {
    const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [window.innerWidth]);
    return (
        <div className={styles.hero}>
            <div className={styles.bg}>
                <img className={styles.PcBg} src={windowWidth >= 1100? hero : hero_mobile} alt="Hero Background" />
                <div className={styles.details}>
                    <div className={styles.top}>
                        <div className={styles.bold}>
                            <p>Effortless<br/>Eco-Friendly<br/>Car Wash Solutions</p>
                        </div>
                        <div className={styles.thin}>
                            <p>We deliver spotless results at scale, keeping your fleet and executives’ vehicles pristine.</p>
                        </div>
                    </div>
                    <div className={styles.bottom}>
                        <a href="#footer"><button >Contact Us</button></a>
                    </div>
                </div>
            </div>
        </div>
    )
}
