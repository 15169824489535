import React from 'react'
import styles from './Cards.module.scss';

interface Input{
    head:string
    content:string
}

export default function Cards({ head, content }: Input) {
    return (
        <div className={styles.card}>
            <p className={styles.head}>{head}</p>
            <p className={styles.content}>{content}</p>
        </div>
    )
}
